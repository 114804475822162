import {DetailValue, StyledLink} from '@eon.cz/apollo13-frontend-common';
import {Komodita, ObchodniPartnerTyp, SmlouvaOdbernehoMista} from '@eon.cz/apollo13-graphql';
import {Grid} from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import {ReactNode} from 'react';
import {FormattedMessage} from 'react-intl';
import {PageRoute, RouteService} from '../../Common';
import {useTablet} from '../../Common/services/PageService';
import {formatAddress} from '../../Utils';

type Props = {
    om: SmlouvaOdbernehoMista;
    komodita: Komodita | undefined | null;
    menu: ReactNode;
};
export const OdbernaMistaCard = ({om, komodita, menu}: Props) => {
    const isTablet = useTablet();

    const isElektrina = komodita === Komodita.ELEKTRINA;
    const adresa = isElektrina ? om.elektrina?.adresa : om.plyn?.adresa;
    return (
        <Card sx={{borderRadius: 5}}>
            <CardHeader
                avatar={
                    <Typography variant="h6">
                        <FormattedMessage id={isElektrina ? 'ean' : 'eic'} />
                    </Typography>
                }
                action={menu}
                title={
                    <StyledLink
                        href={{
                            pathname: RouteService.getPathname(PageRoute.SMLOUVY_ODBERNYCH_MIST, '/detail', komodita),
                            query: {id: om.id},
                        }}
                        asVariables={om.id}
                    >
                        {!isElektrina ? om?.plyn?.eic : om?.elektrina?.eanSpotrebni}
                    </StyledLink>
                }
            />
            <CardContent>
                <Grid container spacing={1}>
                    {isElektrina && om.elektrina?.eanVyrobni && (
                        <Grid item xs={12}>
                            <DetailValue title="odberna.mista.vyrobna.ean.vyrobni" value={om.elektrina?.eanVyrobni} inline={isTablet} />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <DetailValue title="odberna.mista.nazev" value={om.nazev ?? 'Neuvedeno'} tooltipText="odberna.mista.nazev.tooltip" inline={isTablet} />
                    </Grid>
                    <Grid item xs={12}>
                        <DetailValue
                            title="odberna.mista.zakaznik"
                            value={
                                om.zakaznik.typ === ObchodniPartnerTyp.PRAVNICKA_OSOBA
                                    ? om.zakaznik.nazevSpolecnosti
                                    : `${om.zakaznik.jmeno} ${om.zakaznik.prijmeni}`
                            }
                            inline={isTablet}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <DetailValue title="adresa.odberneho.mista" value={formatAddress(adresa)} inline={isTablet} />
                    </Grid>
                    <Grid item xs={12}>
                        <DetailValue title="odberna.mista.typ.mereni" value={om.typMereni ?? 'Neuvedeno'} inline={isTablet} />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};
